import peticionHttp from "../peticionHttp";
const service = "api";

export const getAttendancesListService = async () => {
  const response = await peticionHttp.get(`${service}/attendances`);
  return response;
};

export const addAttendanceService = async (url, payload) => {
  const response = await peticionHttp.post(url, payload);
  return response;
};

export const getAttendanceCodeService = async () => {
  const response = await peticionHttp.get(`${service}/settings/assistance-code`);
  return response;
};
