<template>
  <div>
    <bread-layout :items="breadcrumbLinks"></bread-layout>
    <div class="row">
      <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
    </div>
    <attendances-capture :attendances="attendances"></attendances-capture>
    <attendances-calendar
      v-if="!loading"
      :attendances="attendances"
    ></attendances-calendar>
  </div>
</template>

<script>
import AlertsPayment from "@/views/profile/AlertsPayment.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { defineAsyncComponent, onMounted } from "@vue/runtime-core";
import BreadLayout from "../../layouts/BreadLayout.vue";
import useGetAttendances from "../../composables/attendances/useGetAttendances.js";
import AttendancesCapture from "./AttendancesCapture.vue";
const AttendancesCalendar = defineAsyncComponent(() =>
  import("./AttendancesCalendar.vue")
);
export default {
  components: {
    BreadLayout,
    AttendancesCapture,
    AttendancesCalendar,
    AlertsPayment,
  },
  setup() {
    onMounted(() => {
      window.scrollTo( 0, 0 );
    });
    const store = useStore();
    const breadcrumbLinks = ref([
      {
        text: "Asistencias",
        path: "/asistencias",
      },
    ]);

    const { loading, getAttendances, data: attendances } = useGetAttendances();

    getAttendances();

    return {
      breadcrumbLinks,
      loading,
      attendances,
      getAuthUser: computed(() => store.state.User.user),
    };
  },
};
</script>

<style>
</style>
