<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div
                        class="d-flex justify-content-center align-items-center flex-column stream"
                    >
                        <transition name="fade" mode="out-in">
                            <button class="btn btn-primary" @click="stream = true" v-if="!stream">
                                <i class="fa fa-camera"></i> <span class="ml-1">Capturar asistencia</span>
                            </button>
                            <template v-else>
                                <div>
                                    <qr-stream @decode="onDecode" class="mb">
                                        <div style="color: red;" class="frame"></div>
                                    </qr-stream>
                                    <div class="mt-4 text-center">
                                        <small>Escanea el código QR proporcionado por tu entrenador</small>
                                        <button class="btn btn-danger mt-3" @click="stream = false"><i class="fa fa-times-circle"></i> <span class="ml-1">Cancelar escaneo</span></button>
                                    </div>
                                    <div v-show="loading">
                                        <div class="spinner mx-auto"></div>
                                        <div class="text-center">
                                            <small class="mt-2 d-block">Procesando solicitud...</small>
                                            <small class="mt-2 d-block">Puedes dejar de escanear</small>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { QrStream } from 'vue3-qr-reader';
import useGetAttendanceCode from "../../composables/attendances/useGetAttendanceCode.js";
import useAddAttendance from "../../composables/attendances/useAddAttendance.js";
export default {
    components: {
        QrStream,
    },
    props: {
        attendances: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        // composable para obtener el código de asistencia
        const {
            getAttendanceCode,
            data: code,
        } = useGetAttendanceCode();

        // composable para crear la asistencia
        const {
            loading,
            addAttendance,
        } = useAddAttendance();

        // toggle QR scanner
        const stream = ref(false)
        const link = ref(null)

        // Obtiene el link del QR
        const onDecode = async (url) => {
            link.value = url
            if (url.includes('api/attendances')) {
                await getAttendanceCode()
                const res = await addAttendance(url, { assistance_code: code.value, from_app: true })
                stream.value = false
                console.log(res);
                props.attendances.push(res)
            } else {
                Swal.fire(
                    "Error!",
                    "El código QR no coincide",
                    "error"
                )
            }
        }
        return {
            onDecode,
            stream,
            link,
            loading,
        }
    }
};
</script>

<style scoped>
.stream {
    max-height: 500px;
    max-width: 500px;
    margin: auto;
}

.frame {
    border-style: solid;
    border-width: 2px;
    border-color: rgb(108, 197, 29);
    height: 200px;
    width: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* spinner */
.spinner {
    border: 4px solid rgba(108, 197, 29, 0.8);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
