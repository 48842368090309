import { ref } from "@vue/reactivity";
import { addAttendanceService } from "../../services/attendances/attendancesServices";
export default () => {
    const loading = ref(false);
    const addAttendance = async (url, payload) => {
        NProgress.start();
        loading.value = true;
        const response = await addAttendanceService(url, payload);
        if (response.status === 200) {
            Swal.fire("Hecho", response.message, "success")
        } else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done();
        return response.data;
    };
    return {
        loading,
        addAttendance,
    };
};
